.editor div {
	border: 1px solid #1b3b5f;
	padding: 10px 10px 20px 30px;
	border-radius: 0 0 5px 5px;
	min-height: 150px;
	transition: 0.2s;
	font-family: Rubik;
}

.editor a {
	color: #1b3b5f;
	text-decoration: underline;
}

.editor div:focus {
	box-shadow: #1b3b5f25 0 0 0 0.2rem;
	border-color: #1b3b5f;
}

.toolbar {
	border: 1px solid #1b3b5f;
	border-width: 1px 1px 0 1px;
	padding: 5px;
	border-radius: 5px 5px 0 0;
	display: flex;
}

.ProseMirror-focused {
	outline: none;
}

.button-rich {
	padding: 4px;
	background-color: transparent;
	border: 0;
	border-radius: 5px;
	transition: 0.2s;
	cursor: pointer;
	margin-right: 3px;
}

.button-rich svg {
	color: #1b3b5f;
}

.button-rich:hover {
	background-color: #1b3b5f20;
}

.button-rich.active {
	background-color: #1b3b5f;
}

.button-rich.active svg {
	color: #d4cdc9;
}
